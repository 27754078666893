.order-container {
  position: absolute;
  top: 70px;
  right: 70px;
  z-index: 2;
  width: 412px;
  height: 721px;
  color: #ffffff;
  background-color: #000000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  &--close {
    display: block;
    position: absolute;
    z-index: 10;
    outline: none;
    overflow: hidden;
    cursor: pointer;
    top: 16px;
    right: 16px;
    width: 28px;
    height: 28px;
    background-color: #ffffff;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url('./icons/close-line.svg');
    border: none;
    border-radius: 28px;
    text-indent: -2000px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);

  }

  &--header {
    padding: 20px;
    border-bottom: 1px solid #D5DADE;
  }

  &--headline {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
  }

  &--back-button {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    color: #ffffff;
    outline: none;
    text-decoration: none;
    transition: 0.4s;

    & > span {
      font-weight: 300;
    }

    &:hover {
      color: #FFBD05;
    }
  }

  &--subheadline {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  &--list {
    width: 100%;
    height: 480px;
    overflow: auto;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--menu-group {
    border-bottom: 1px solid #D5DADE;

    & h3 {
      padding: 10px 20px;
      border-bottom: 1px solid #D5DADE;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
    }
  }

  &--menu-item {
    display: flex;
    padding: 10px 20px;
    border-bottom: 1px solid #D5DADE;
    align-items: center;
    text-decoration: none;
    transition: 0.4s;

    &--image {
      overflow: hidden;
      width: 95px;
      height: 65px;
      margin-right: 10px;
      border: 2px solid rgba(#FFBD05,0);
      border-radius: 8px;
      transition: 0.4s;
      
      text-align: center;

      & img {
        width: 95px;
        height: 65px;
        object-fit: cover;
      }
    }

    &--title {
      width: 150px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      color: #ffffff;
      text-decoration: none;
      margin: 0;
      margin-right: auto;
      transition: 0.4s;
    }

    &--button {
      width: 97px;
      height: 50px;
      text-align: center;
      text-decoration: none;
      border: 3px solid #FFBD05;
      border-radius: 50px;
      outline: none;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      padding: 10px;
      transition: 0.4s;

      &:hover {
        background-color: #FFBD05;
        color: #000000;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {

      .order-container--menu-item--image {
        border: 2px solid #FFBD05;
      }

      .order-container--menu-item--title {
        color: #FFBD05;
      }
    }
  }

  &--detail {
    &--img-container {
      width: 100%;
      height: 240px;
      overflow: hidden;
      text-align: center;

      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url('./icons/preloader-image.svg');
      
      & > img {
        width: 100%;
        height: 240px;
        object-fit: cover;
      }
    }

    &--inner {
      padding: 30px 20px;

      & > h2 {
        font-size: 23px;
        line-height: 27px;
        color: #ffffff;
        font-weight: 700;
        padding-bottom: 20px;
      }
    }

    &--price {
      color: #FFBD05;
      text-decoration: none;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
    }

    &--button {
      display: block;
      width: 100%;
      height: 50px;
      text-align: center;
      text-decoration: none;
      border: 3px solid #FFBD05;
      border-radius: 50px;
      outline: none;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      padding: 10px;
      margin-top: 40px;
      transition: 0.4s;

      &:hover {
        background-color: #FFBD05;
        color: #000000;
      }
    }
  }

  .order-confirmation {
    z-index: 1;

    &--image {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 240px;
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url('./icons/preloader-image.svg');
      
      & > img {
        z-index: 1;
        width: 100%;
        height: 240px;
        object-fit: cover;
      }

      .order-confirmation-icon {
        position: absolute;
        left: 50%;
        bottom: -62px;
        margin-left: -62px;
        width: 124px;
        height: 124px;
        border-radius: 124px;
        background-color: #FFBD05;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-image: url('./icons/order-submit-check.svg');
      }
    }
    &--info {
      position: relative;
      padding: 90px 30px 0 30px;
      text-align: center;

      & > h2 {
        width: 280px;
        margin: 0 auto;
        font-size: 23px;
        line-height: 27px;
        color: #ffffff;
        font-weight: 700;
        padding-bottom: 20px;
      }
    }
    &--button {
      display: block;
      width: 100%;
      height: 50px;
      text-align: center;
      text-decoration: none;
      background-color: #000000;
      border: 3px solid #FFBD05;
      border-radius: 50px;
      outline: none;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      padding: 10px;
      margin-top: 40px;
      cursor: pointer;
      transition: 0.4s;

      &:hover {
        background-color: #FFBD05;
        color: #000000;
      }
    }
  }
}