.map-layer {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #000;
  outline: none;

  & > * {
    outline: none;
  }

  & .cluster {
    outline: none;
    & img {
      width: 100%;
      height: 100%;
    }
    span {
      color: #EAB810;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      padding-top: 0px;
      text-align: center;
      text-shadow: 0 0 3px 0 rgba(0,0,0,0.75);
    }
  }
}

