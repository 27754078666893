.dashboard {
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 2340px auto;
  background-position: 50% 50%;
  background-image: url('./images/dashboard.jpg');
  text-align: center;

  &--container {
    z-index: 2;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    width: 1280px;
    height: 640px;
    margin-left: -640px;
    margin-top: -320px;
    text-align: left;
    background-color: #f2f2f2;
    border-radius: 4px;
    box-shadow: inset 0 0 10px #000000;
    outline: none;
  }
  
  @media (min-width: 960px) and (max-width: 1280px) { 
    background-size: 1755px auto;

    &--container {
      width: 960px;
      height: 480px;
      margin-left: -480px;
      margin-top: -240px;
    }
  }

  @media (min-width: 640px) and (max-width: 960px) { 
    background-size: 1170px auto;

    &--container {
      width: 640px;
      height: 320px;
      margin-left: -320px;
      margin-top: -160px;
    }
  }
}