.dark-mode {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 2;

  /* The switch - the box around the slider */
  &--switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;

    /* Hide default HTML checkbox */
    & input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .dark-mode--slider {
        background-color: #393939;
        box-shadow: 0 0 3px #f2f2f2;
      }
      
      &:focus + .dark-mode--slider {
        box-shadow: 0 0 3px #f2f2f2;
      }

      &:checked + .dark-mode--slider:before {
        transform: translateX(30px);
        background: white url('./icons/contrast-2-fill.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  /* The slider */
  &--slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #4BA82E;
    box-shadow: 0 0 3px #2020203d;

    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 32px;
      width: 32px;
      left: 0px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      box-shadow: 0 0px 15px #2020203d;
      background: white url('./icons/contrast-2-line.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &.dark-mode--slider-round {
      border-radius: 32px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}