@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-Thin.eot');
  src: url('../static/fonts/SKODANext-Thin.woff2') format("woff2"),
  url('../static/fonts/SKODANext-Thin.woff') format("woff"),
  url('../static/fonts/SKODANext-Thin.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-Thin.otf') format('opentype'),
  url('../static/fonts/SKODANext-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-ThinItalic.eot');
  src: url('../static/fonts/SKODANext-ThinItalic.woff2') format("woff2"),
  url('../static/fonts/SKODANext-ThinItalic.woff') format("woff"),
  url('../static/fonts/SKODANext-ThinItalic.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-ThinItalic.otf') format('opentype'),
  url('../static/fonts/SKODANext-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-Light.eot');
  src: url('../static/fonts/SKODANext-Light.woff2') format("woff2"),
  url('../static/fonts/SKODANext-Light.woff') format("woff"),
  url('../static/fonts/SKODANext-Light.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-Light.otf') format('opentype'),
  url('../static/fonts/SKODANext-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-LightItalic.eot');
  src: url('../static/fonts/SKODANext-LightItalic.woff2') format("woff2"),
  url('../static/fonts/SKODANext-LightItalic.woff') format("woff"),
  url('../static/fonts/SKODANext-LightItalic.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-LightItalic.otf') format('opentype'),
  url('../static/fonts/SKODANext-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-Regular.eot');
  src: url('../static/fonts/SKODANext-Regular.woff2') format("woff2"),
  url('../static/fonts/SKODANext-Regular.woff') format("woff"),
  url('../static/fonts/SKODANext-Regular.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-Regular.otf') format('opentype'),
  url('../static/fonts/SKODANext-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-RegularItalic.eot');
  src: url('../static/fonts/SKODANext-RegularItalic.woff2') format("woff2"),
  url('../static/fonts/SKODANext-RegularItalic.woff') format("woff"),
  url('../static/fonts/SKODANext-RegularItalic.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-RegularItalic.otf') format('opentype'),
  url('../static/fonts/SKODANext-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-Regular.eot');
  src: url('../static/fonts/SKODANext-Regular.woff2') format("woff2"),
  url('../static/fonts/SKODANext-Regular.woff') format("woff"),
  url('../static/fonts/SKODANext-Regular.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-Regular.otf') format('opentype'),
  url('../static/fonts/SKODANext-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-RegularItalic.eot');
  src: url('../static/fonts/SKODANext-RegularItalic.woff2') format("woff2"),
  url('../static/fonts/SKODANext-RegularItalic.woff') format("woff"),
  url('../static/fonts/SKODANext-RegularItalic.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-RegularItalic.otf') format('opentype'),
  url('../static/fonts/SKODANext-RegularItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-Bold.eot');
  src: url('../static/fonts/SKODANext-Bold.woff2') format("woff2"),
  url('../static/fonts/SKODANext-Bold.woff') format("woff"),
  url('../static/fonts/SKODANext-Bold.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-Bold.otf') format('opentype'),
  url('../static/fonts/SKODANext-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-BoldItalic.eot');
  src: url('../static/fonts/SKODANext-BoldItalic.woff2') format("woff2"),
  url('../static/fonts/SKODANext-BoldItalic.woff') format("woff"),
  url('../static/fonts/SKODANext-BoldItalic.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-BoldItalic.otf') format('opentype'),
  url('../static/fonts/SKODANext-BoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-Black.eot');
  src: url('../static/fonts/SKODANext-Black.woff2') format("woff2"),
  url('../static/fonts/SKODANext-Black.woff') format("woff"),
  url('../static/fonts/SKODANext-Black.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-Black.otf') format('opentype'),
  url('../static/fonts/SKODANext-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "SkodaNext";
  src: url('../static/fonts/SKODANext-BlackItalic.eot');
  src: url('../static/fonts/SKODANext-BlackItalic.woff2') format("woff2"),
  url('../static/fonts/SKODANext-BlackItalic.woff') format("woff"),
  url('../static/fonts/SKODANext-BlackItalic.eot#iefix') format('embedded-opentype'),
  url('../static/fonts/SKODANext-BlackItalic.otf') format('opentype'),
  url('../static/fonts/SKODANext-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

