@import './styles/colors.scss';
@import './styles/typography.scss';
@import './styles/fonts.scss';

html {
  margin: 0;
  padding: 0;
  background-color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  box-sizing: border-box;
  font-size: $font-size;
  font-family: $font-family;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-rendering: geometricPrecision;
}

ol,
ul {
  list-style: none;
}

*::selection {
  background: $color-action-primary;
  color: $color-text-primary;
}

.app {
  min-height: 100vh;
}

.text-center {
  text-align: center;
}


